import * as React from "react";
import {ChangeEvent, useEffect, useId, useLayoutEffect, useRef, useState} from "react";
import {
  Avatar,
  Button,
  ButtonProps,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import {BottomDrawerProps} from "./types";
import SearchIcon from "../Icons/SearchIcon";
import Puller from "../Puller";
import {grey} from "@mui/material/colors";


const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: '100%',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: '40px',
  borderBottomRightRadius: '40px',
  px: 1,
  backgroundColor: theme.palette.mode === 'light' ?
    grey[100] : grey[900],
  fontSize: '14px',
  border: 'none',
  '&:hover': {
    border: 'none',
    backgroundColor: theme.palette.mode === 'light' ?
      grey[100] : grey[900],
  }
}));

const BottomDrawer = (props: BottomDrawerProps) => {
  const {
    selectedItem,
    items,
    onSearch,
    disableSearch,
    searchPlaceHolder,
    onSelect,
    defaultValue,
    loading,
    disabled,
    popoverTitle,
    noItemSelectedText,
    DrawerButton
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [width, setWidth] = useState(0);
  const [query, setQuery] = useState('');
  const [value, setValue] = useState(() => {
    return defaultValue !== undefined ? defaultValue : 1;
  });
  const popoverId = useId();
  const containerId = useId();
  const elementRef = useRef<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const title = popoverTitle ? popoverTitle : '';

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value])
  useEffect(() => {
    if (items) {
      
    }
  }, [items])

  useLayoutEffect(() => {
    const handleResize = () => {
      // @ts-ignore
      setWidth(elementRef.current.offsetWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);
  useLayoutEffect(() => {
    setWidth(elementRef?.current?.offsetWidth)
  }, [elementRef?.current?.offsetWidth]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //setAnchorEl(document.getElementById(containerId));
    document.documentElement.style.setProperty('overflow-y', 'hidden', 'important');
    setOpen(true)
  };

  const handleClear = () => {
    setQuery('');
    if (onSearch) {
      onSearch('');
    }

  };

  const handleClose = () => {
    document.documentElement.style.setProperty('overflow-y', 'auto', 'important');
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  }
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    if (onSearch) {
      onSearch(event.target.value);
    }
  }

  return (
    <Grid container
      className={'ran-bottom-drawer'}
      id={containerId}
      ref={elementRef}
    >
      <DrawerButton
        aria-describedby={popoverId}
        onClick={handleClick}
        sx={{
          pl: { xs: 0.25, sm: 1 },
          pr: { xs: 0.5, sm: 1 },
        }}
      >
        {
          selectedItem !== null && (<>
            <Typography
              ml={1}
              noWrap
              flexGrow={1}
              fontSize={{ xs: 'smaller', sm: 'normal' }}
              textAlign={'center'}
              sx={{ direction: 'rtl' }}
            >{selectedItem.title}</Typography>
            {!!selectedItem.icon && (
              <Avatar
                sx={{ width: '24px', height: '24px' }}
                src={selectedItem.icon}
              />
            )}
          </>)
        }
        {
          selectedItem === null && (<>
            <Typography sx={{ textWrap: 'nowrap', overflow: 'hidden' }} flexGrow={1}>{noItemSelectedText}</Typography>
          </>)
        }
      </DrawerButton>
      <Grid item xs={12}>
        <Drawer
          anchor={'bottom'}
          open={open}
          onClose={handleClose}
          sx={{
            zIndex: '12000!important',
            '& .MuiPaper-root.MuiDrawer-paper': {
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
            }
          }}
        >
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              maxHeight: 450,
              overflowY: 'hidden',
              height: 300,
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >
            <li>
              <ul>
                <ListSubheader
                  disableGutters
                >
                  <Puller />
                  {!disableSearch && (
                    <>
                      <TextField
                        fullWidth
                        value={query}
                        onChange={handleSearch}
                        placeholder={searchPlaceHolder ?? ''}
                        sx={{
                          '& fieldset': {
                            borderRadius: '24px',
                            mx: '8px',
                            my: '4px'
                          },
                          '& input': {
                            direction: 'ltr',
                            textAlign: 'left',
                            '&:-moz-placeholder': {
                              textAlign: 'right!important'
                            },
                            '&:-ms-input-placeholder': {
                              textAlign: 'right!important'
                            },
                            '&::-webkit-input-placeholder': {
                              textAlign: 'right!important'
                            }
                          }
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="end"><IconButton
                            onClick={handleClear}><CloseIcon /></IconButton></InputAdornment>,
                          endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                        }}
                      />
                      <Divider />
                    </>
                  )}
                  {
                    loading && <LinearProgress color="inherit" sx={{ height: '2px' }} />
                  }
                </ListSubheader>
                <ListItem sx={{ textAlign: 'right' }}>
                  <ListItemText sx={{ color: 'text.secondary' }}>{title}</ListItemText>
                </ListItem>
                {items.length === 0 ? (
                  <Typography

                    sx={{
                      textAlign: 'center'
                    }}
                  >نتیجه ای یافت نشد</Typography>
                ) : (
                  items.map((item) => (
                    <ListItem
                      selected={selectedItem !== null && item.id === selectedItem.id}
                      key={item.id}
                      onClick={() => {
                        if (onSelect) {
                          onSelect(item)
                        }
                        handleClose()
                      }}
                      disableGutters
                      sx={{
                        py: 0,
                      }}
                    >
                      <ListItemButton disableRipple disableTouchRipple>
                        <ListItemIcon>
                          {!!item.icon && (
                            <Avatar
                              sx={{ width: '16px', height: '16px' }}
                              src={item.icon}
                            />
                          )}
                          <Typography mx={1} color={'text.primary'} fontWeight={'500'}>{item.title}</Typography>
                        </ListItemIcon>
                        {!!item.description && (
                          <ListItemText sx={{ '& .MuiTypography-root': { color: 'text.secondary' }, margin: 0 }} primary={item.description} />
                        )}
                      </ListItemButton>
                    </ListItem>


                  ))
                )}


              </ul>
            </li>
          </List>
        </Drawer>
      </Grid>
    </Grid>
  );
}

export default BottomDrawer;
import * as React from "react";
import {useEffect} from "react";
import {Box} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useNavigate} from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`twin-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `twin-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface TwinTabsProps{
  label1: string;
  label2: string;
  tab1: JSX.Element;
  tab1Path?: string;
  tab2: JSX.Element;
  tab2Path?: string;
  defaultTab?: number;
}
const TwinTabs = (props: TwinTabsProps) => {
  const {
    label1,
    label2,
    tab1,
    tab1Path,
    tab2,
    tab2Path,
    defaultTab
  } = props;
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setValue(defaultTab ? defaultTab : 0);
  }, [defaultTab])
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(tab1Path && newValue === 0){
      navigate(tab1Path);
    }else if(tab2Path && newValue === 1){
      navigate(tab2Path);
    }
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="twin tabs"
          variant="fullWidth"
        >
          <Tab key={'tab0'} sx={{fontSize: '0.875rem'}} label={label1} {...a11yProps(0)} />
          <Tab key={'tab1'} sx={{fontSize: '0.875rem'}} label={label2} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {tab1}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {tab2}
      </CustomTabPanel>
    </Box>
  );
}

export default TwinTabs;
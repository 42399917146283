import * as React from 'react';
import {useEffect, useState} from 'react';

import {default as RootAppBar} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Box, IconButton, useTheme} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import SearchIcon from "../Icons/SearchIcon";
import InfoIcon from "../Icons/InfoIcon";

export default function AppBar() {
  const navigate = useNavigate();
  const {
    pathname
  } = useLocation();
  const theme = useTheme();
  const [searchTarget, setSearchTarget] = useState<'/search' | '/search/ideas' | '/search/blog' | '/search/articles'>('/search');

  useEffect(() => {
    if(pathname.startsWith('/ideas')){
      setSearchTarget('/search/ideas');
    }else if(pathname.startsWith('/blog')){
      setSearchTarget('/search/blog');
    }else if(pathname.startsWith('/articles')){
      setSearchTarget('/search/articles')
    }else{
      setSearchTarget('/search');
    }
  }, [pathname]);

  const handleSearchButtonClick = () => {
    navigate(searchTarget);
  }
  return <RootAppBar
    className={"appbar1"}
    sx={{
      boxShadow: 0,
      background: (theme)=>theme.palette.background.default +'!important',
      color: (theme)=>theme.palette.text.primary +'!important'
    }}
    position="static"
  >
    <Toolbar variant="dense" className={"toolbar"}>
      <IconButton
        component={Link}
        to={"/profile"}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
      >
        <InfoIcon fontSize={"large"} />
      </IconButton>
      
      <Box sx={{flexGrow: 1, textAlign: "center"}} className={"header-logo-wrapper"}>
        {theme.palette.mode === 'dark' && (
          <Box
            component={'img'}
            sx={{width: '128px!important'}}
            src={'/logo-dark.svg'}
          />
        )}
        {theme.palette.mode !== 'dark' && (
          <Box
            component={'img'}
            src={'/logo.svg'}
          />
        )}
      </Box>

      <IconButton
        onClick={handleSearchButtonClick}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        className={"search-btn"}
      >
        <SearchIcon fontSize={"large"}/>
      </IconButton>
    </Toolbar>
  </RootAppBar>
}

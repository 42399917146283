import {useEffect, useState} from "react";
import useCheckLogin from "./use-check-login";
import axios from "axios";

const useUserData = () => {
    const [username, setUsername] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const { accessToken } = useCheckLogin();
    const [watchList, setWatchList] = useState<Array<number>>([]);

    useEffect(() => {

        if (!!accessToken) {

            setLoading(true);
            axios.get('https://appapi.ramzarz.news/api/v1/watchlist', {
                headers: {
                    Authorization: 'Bearer ' + accessToken,
                }
            }).then((response) => {
                if(!!response.data.watchlist){
                    setWatchList(response.data.watchlist.split(','))
                }else{
                    setWatchList([])
                }
            }).catch((err) => {
                setUsername('');
                setWatchList([]);
            }).finally(() => setLoading(false))

        } else {
            setUsername('');
            setWatchList([]);
        }

    }, [accessToken]);

    const addToWatchList = (id: number) => {
        axios.post(`https://appapi.ramzarz.news/api/v1/add-to-watchlist/${id}`, {},{
            headers: {
                Authorization: 'Bearer ' + accessToken,
            }
        }).then((response) => {
            if(!!response.data.watchlist){
                setWatchList(response.data.watchlist.split(','))
            }else{
                setWatchList([])
            }
        })
    };

    const removeFromWatchList = (id: number) => {
        axios.delete(`https://appapi.ramzarz.news/api/v1/remove-from-watchlist/${id}`, {
            headers: {
                Authorization: 'Bearer ' + accessToken,
            }
        }).then((response) => {
            if(!!response.data.watchlist){
                setWatchList(response.data.watchlist.split(','))
            }else{
                setWatchList([])
            }
        })
      }

      const isWatchListed =  (id: number) => {
          // @ts-ignore
          return watchList.includes(id);
      }

    return {
        username,
        loading,
        watchList,
        addToWatchList,
        removeFromWatchList,
        isWatchListed
    };
}

export default useUserData;
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import OtpInput from 'react-otp-input';
import {useLocation, useNavigate} from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import {useEffect, useState} from "react";
import {darken} from '@mui/system';
import axios from "axios";
import useCheckLogin from "../../hooks/use-check-login";

export default function OtpDetails() {

    const navigate = useNavigate();
    const theme = useTheme();
    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(10);
    const location = useLocation();
    const contactInfo = location.state?.contactInfo;
    const typeInput = location.state?.typeInput;
    const colorDefaultLogin = theme.palette.mode === 'light' ?
        darken(theme.palette.secondary.light, 0.1) : darken(theme.palette.secondary.light, 0.6);
    const [ready, setReady] = useState(false);
    const {login} = useCheckLogin();

    useEffect(() => {
      if(ready){
        axios.post('https://appapi.ramzarz.news/api/v1/otp', {
          trackid: location.state?.trackid,
          code: otp
        }).then((response) => {
          login(response.data.token)
          navigate('/');
        }).finally(() => {
          setReady(false)
        })
      }
    }, [ready])

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prev) => {
                if (prev > 0) {
                    return prev - 1;
                } else {
                    clearInterval(interval);
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const secs = totalSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };


    const handleChange = (otpValue: string) => {
        if(otp.length == 4 && otpValue.length == 5){
          setReady(true);
        }
        setOtp(otpValue);
    };

    const handleBackButtonClick = () => {
        navigate('/');
    }
    const handleRepeatOtp = () => {
        navigate('/');
    }
    const handleEditInput = () => {
        navigate('/login');
    }

    return (
        <Container disableGutters maxWidth={false} sx={{ px: 1, mt: 4 }} >
            <Card variant="outlined"
                sx={{
                    minWidth: 275,
                    border: '0px',
                }}>
                <CardHeader
                    sx={{
                        marginTop: '0px',
                        paddingRight: '0px',
                        paddingTop:'0px',

                        '& .MuiCardHeader-avatar': {
                            marginRight: '0px',
                        },
                        '& .MuiCardHeader-title': {
                            fontSize: '15px',
                            textAlign: 'center'
                        },
                        avatar: {
                            marginRight: '0px'
                        }
                    }}
                    avatar={

                        <IconButton
                            sx={{ marginRight: '0px !important' }}
                            onClick={handleBackButtonClick}
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <EastIcon fontSize={"large"} />
                        </IconButton>
                    }
                    title={`ثبت نام / ورود`}
                />
                <CardContent
                    sx={{
                        paddingBottom: '16px !important',
                        borderRadius: '10px',
                        border: `1px solid ${colorDefaultLogin}`
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 2,
                            mb: 4

                        }}
                    >
                        <Box
                            component="img"
                            sx={{ width: '128px!important', marginRight: 0, }}
                            src={theme.palette.mode === 'dark' ? '/logo-dark.svg' : '/logo.svg'}
                        />
                    </Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            justifyContent: "space-between",
                        }}>
                        <Typography variant="h6" >تایید
                            <Box
                                component='span'
                                sx={{
                                    marginRight: '3px'
                                }}
                            >
                                {typeInput === 'mobile' ? ' شماره موبایل' : 'ایمیل'}

                            </Box>
                        </Typography>
                        <Button
                            variant="text"
                            onClick={handleEditInput}
                            sx={{
                                marginLeft: '0px !important',
                                color: 'primary.main'
                            }}
                        >
                            ویرایش
                            <Box
                                component='span'
                                sx={{
                                    marginRight: '3px'
                                }}
                            >
                                {typeInput === 'mobile' ? ' شماره موبایل ' : ' ایمیل '}
                            </Box>

                        </Button>
                    </Stack>
                    <Typography my={2}>

                        کد تایید ارسال شده به
                        {typeInput === 'mobile' ? ' شماره موبایل ' : ' ایمیل '}
                        <Box
                            component="span"
                            sx={{
                                color: 'primary.main',
                                marginX: '5px'
                            }}
                        >
                            {contactInfo}
                        </Box>
                        را وارد کنید:
                    </Typography>
                    <Box
                        className='boxOtp'
                        sx={{
                            mt: 4
                        }}
                    >
                        <OtpInput
                            shouldAutoFocus={true}
                            value={otp}
                            onChange={handleChange}
                            numInputs={5}
                            renderInput={(props) => (
                                <input
                                    {...props}
                                    inputMode="numeric" 
                                    pattern="[0-9]*"
                                />
                            )}
                            inputType='tel'
                            containerStyle={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(5,1fr)',
                                direction: 'ltr',
                                gap: '8px',
                                alignItems: 'center',
                                width: '100%',
                                maxWidth: '350px',
                                margin: '0px auto'
                            }}
                            inputStyle={{
                                color: theme.palette.mode === 'light' ? 'black' : 'white',
                                justifySelf: 'center',
                                border: `1px solid  ${colorDefaultLogin}`,
                                height: '48px',
                                textAlign: 'center',
                                width: '48px',
                                borderRadius: '10px',
                                backgroundColor: 'transparent',
                                fontWeight:'500',
                            }}
                        />

                    </Box>
                    <Box
                        sx={{
                            textAlign: 'center',
                            mt: 4,
                            mb: 3,
                        }}
                    >
                        {seconds > 0 &&
                            <Typography
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontWeight: 'bold'
                                }}
                            >
                                {formatTime(seconds)}
                            </Typography>
                        }
                        {seconds === 0 && (
                            <Button
                                onClick={handleRepeatOtp}
                                variant='text'
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main'
                                }}
                            >
                                دریافت مجدد کد تایید
                            </Button>
                        )}

                    </Box>


                </CardContent>
            </Card>



        </Container >
    );
}
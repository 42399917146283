import * as React from "react";
import {ChangeEvent, useEffect, useId, useLayoutEffect, useRef, useState} from "react";
import {
  Avatar,
  Button,
  ButtonProps,
  darken,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Typography
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {NumericFormat} from "react-number-format";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import NumberInput from './NumberInput'
import {CurrencySelectorProps} from "./types";
import SearchIcon from "../Icons/SearchIcon";
import Puller from "../Puller";
import {grey} from "@mui/material/colors";


const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  height: '100%',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: '40px',
  borderBottomRightRadius: '40px',
  px: 1,
  backgroundColor: theme.palette.mode === 'light' ?
    grey[100] : grey[900],
  fontSize: '14px',
  border: 'none',
  '&:hover': {
    border: 'none',
    backgroundColor: theme.palette.mode === 'light' ?
      grey[100] : grey[900],
  }
}));

const CurrencySelector = (props: CurrencySelectorProps) => {
  const {
    selectedItem,
    items,
    onSearch,
    onChange,
    onSelect,
    defaultValue,
    loading,
    disabled,
    decimals,
    popoverTitle
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [width, setWidth] = useState(0);
  const [query, setQuery] = useState('');
  const [value, setValue] = useState(() => {
    return defaultValue !== undefined ? defaultValue : 1;
  });
  const popoverId = useId();
  const containerId = useId();
  const elementRef = useRef<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const title = popoverTitle ? popoverTitle : 'ارزهای دیجیتال';

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value])

  useLayoutEffect(() => {
    const handleResize = () => {
      // @ts-ignore
      setWidth(elementRef.current.offsetWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);
  useLayoutEffect(() => {
    setWidth(elementRef?.current?.offsetWidth)
  }, [elementRef?.current?.offsetWidth]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //setAnchorEl(document.getElementById(containerId));
    document.documentElement.style.setProperty('overflow-y', 'hidden', 'important');
    setOpen(true)
  };

  const handleClose = () => {
    document.documentElement.style.setProperty('overflow-y', 'auto', 'important');
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  }
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    if (onSearch) {
      onSearch(event.target.value);
    }
  }

  return (
    <Grid container
      className={'ran-currency-selector'}
      id={containerId}
      ref={elementRef}
    >
      <Grid item xs={4}>
        <StyledButton
          aria-describedby={popoverId}
          onClick={handleClick}
          fullWidth
          variant={'outlined'}
          size={'large'}
        >
          {
            selectedItem !== null && (<>
              <KeyboardArrowDownIcon sx={{ color: (theme) => theme.palette.text.primary }} />
              <Typography
                fontWeight={'800'}
                mx={1}
                flexGrow={1}
                textAlign={'left'}
              >
                {selectedItem.symbol}
              </Typography>
              <Avatar
                sx={{
                  width: '24px',
                  height: '24px'
                }}
                src={selectedItem.icon}
              />
            </>)
          }
          {
            selectedItem === null && (<>
              <KeyboardArrowDownIcon />
              <Typography sx={{ textWrap: 'nowrap', overflow: 'hidden' }} flexGrow={1}>لطفا یک گزینه انتخاب
                کنید</Typography>
            </>)
          }
        </StyledButton>
      </Grid>
      <Grid item xs={8} sx={{
        borderRightColor: (theme) => darken(theme.palette.divider, 0.1),
        borderRightWidth: '2px',
        borderRightStyle: 'solid',
      }}>
        <NumericFormat
          value={value}
          allowNegative={false}
          decimalScale={decimals}
          thousandSeparator=","
          type={'tel'}
          disabled={disabled === true}
          customInput={NumberInput}
          onValueChange={(values, sourceInfo) => {
            let v = values.floatValue === undefined ? 0 : values.floatValue;
            if (sourceInfo.source === 'event' && onChange) {
              onChange(v)
            }
            if (props.value === undefined) {
              setValue(v);
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Drawer
          anchor={'bottom'}
          open={open}
          onClose={handleClose}
          sx={{
            zIndex: '12000!important',
            '& .MuiPaper-root.MuiDrawer-paper': {
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
            }
          }}
        >
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              maxHeight: 450,
              overflowY: 'hidden',
              height: 300,
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >
            <li>
              <ul>
                <ListSubheader
                  disableGutters
                >
                  <Puller />
                  <TextField
                    fullWidth
                    value={query}
                    onChange={handleSearch}
                    placeholder={'نام ارز موردنظر را وارد کنید'}
                    sx={{
                      '& fieldset': {
                        borderRadius: '24px',
                        mx: '8px',
                        my: '4px'
                      },
                      '& input': {
                        direction: 'ltr',
                        textAlign: 'left',
                        '&:-moz-placeholder': {
                          textAlign: 'right!important'
                        },
                        '&:-ms-input-placeholder': {
                          textAlign: 'right!important'
                        },
                        '&::-webkit-input-placeholder': {
                          textAlign: 'right!important'
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="end"><IconButton
                        onClick={handleClose}><CloseIcon /></IconButton></InputAdornment>,
                      endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                  />
                  <Divider />
                  {
                    loading && <LinearProgress color="inherit" sx={{ height: '2px' }} />
                  }
                </ListSubheader>
                <ListItem sx={{ textAlign: 'right' }}>
                  <ListItemText sx={{ color: 'text.secondary' }}>{title}</ListItemText>
                </ListItem>
                {items.map((item) => (
                  <ListItem
                    selected={selectedItem !== null && item.id === selectedItem.id}
                    key={item.id}
                    onClick={() => {
                      if (onSelect) {
                        onSelect(item)
                      }
                      handleClose()
                    }}
                    disableGutters
                    sx={{
                      py: 0,
                    }}
                  >
                    <ListItemButton disableRipple disableTouchRipple>
                      <ListItemIcon>
                        <Avatar
                          sx={{ width: '16px', height: '16px' }}
                          src={item.icon}
                        />
                        <Typography mx={1} color={'text.primary'} fontWeight={'500'}>{item.symbol}</Typography>
                      </ListItemIcon>
                      <ListItemText sx={{ '& .MuiTypography-root': { color: 'text.secondary' }, margin: 0 }} primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </ul>
            </li>

          </List>
        </Drawer>
      </Grid>
    </Grid>
  );
}

export default CurrencySelector;
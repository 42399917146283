import {Box} from "@mui/material";
import * as React from "react";
import LoginDetails from "../../components/LoginDetails";

export default function Login() {
  return (
    <Box>
      <LoginDetails/>
    </Box>
  );
}

import {useEffect} from "react";
import * as Sentry from "@sentry/react";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,} from "react-router-dom";

Sentry.init({
  dsn: "https://204ec9c5f95b0c294ddef775c0657049@o4507362471247872.ingest.de.sentry.io/4508245902032976",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^\//, /^https:\/\/app\.ramzarz\.news/, /^https:\/\/staging-app\.ramzarz\.news/, /^https:\/\/ramzarz\.news/, /^https:\/\/appapi\.ramzarz\.news/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
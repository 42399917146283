import * as React from "react";
import Ideas from "../Ideas";
import IdeasIcon from "../Icons/IdeasIcon";
import {Box, useTheme} from "@mui/material";

const Icon = () => {
  const theme = useTheme();
  return (
    <Box component={'span'} pl={0.5}>
      <IdeasIcon fill={theme.palette.mode === 'light' ? 'dark' : 'white'} size={24} type={'outlined'} />
    </Box>
  );
}
export default function IdeasList() {

  return (
    <Ideas
      url="https://app.ramzarz.news/node/api/v1/ideas?limit=20"
      title="جدیدترین تحلیل‌ها"
      singlePath="/ideas/:id/:slug"
      icon={<Icon />}
    ></Ideas>
  );
}

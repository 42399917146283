import React from "react";
import {createBrowserRouter, Navigate} from "react-router-dom";

import Home from "../pages/home";
import News from "../pages/news";
import Articles from "../pages/articles";
import App from "../App";
import SingleNews from "../pages/single-news";
import SingleCurrency from "../pages/single-currency";
import Profile from "../pages/profile";
import About from "../pages/about";
import Terms from "../pages/terms";
import Search from "../pages/search";
import Exchanges from "../pages/exchanges";
import Blog from "../pages/blog";
import Ideas from "../pages/ideas";
import ErrorPage from "../pages/ErrorPage";
import BtcPrice from "../pages/live/btc-price";
import Login from "../pages/login";
import Otp from "../pages/Otp";
import useCheckLogin from "../hooks/use-check-login";

interface ProtectedRouteProps {
  element: JSX.Element;
  restrictedPaths?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  restrictedPaths = []
}) => {
  const { isLoggedIn } = useCheckLogin();
  const currentPath = window.location.pathname;
  if (isLoggedIn && restrictedPaths.includes(currentPath)) {
    return <Navigate to="/" />;
  }

  return element;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/currencies",
        element: <Home />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/news/:id/:slug",
        element: <SingleNews />,
      },
      {

        path: "/articles/:slug?",
        element: <Articles/>,
      },
      {
        path: "/blog/:slug?",
        element: <Blog/>,
      },
      {
        path: "/ideas/:slug?",
        element: <Ideas/>,
      },
      {
        path: "/calculator",
        element: <Home />,
      },
      {
        path: "/exchanges/:currency",
        element: <Exchanges />,
      },
    ],
  },
  {
    path: "/currencies/:slug",
    element: <SingleCurrency />,
    errorElement: <ErrorPage />,
  },
  {
    errorElement: <ErrorPage />,
    path: "/search/ideas",
    element: <Search/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/search/articles",
    element: <Search/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/search/blog",
    element: <Search/>,
  },
  {
    path: "/search",
    element: <Search />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms",
    element: <Terms />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/live/btcprice",
    element: <BtcPrice />,
  },
  {
    path: "/login",
    element: (
      <ProtectedRoute
        element={<Login />}
        restrictedPaths={["/login", "/otp"]}
      />
    ),
  },
  {
    path: "/otp",
    element: <Otp />,
  },
]);

export default router;

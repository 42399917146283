import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  styled,
  Switch,
  useTheme
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import EastIcon from "@mui/icons-material/East";
import * as React from "react";
import {useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import TermsIcon from "../../components/Icons/TermsIcon";
import AboutUsIcon from "../../components/Icons/AboutUsIcon";
import TelegramIcon from "../../components/Icons/TelegramIcon";
import AttachIcon from "../../components/Icons/AttachIcon";
import InstagramIcon from "../../components/Icons/InstagramIcon";
import AparatIcon from "../../components/Icons/AparatIcon";
import ThemedTelegramIcon from "../../components/Icons/ThemedTelegramIcon";
import SwitchThemeIcon from "../../components/Icons/SwitchThemeIcon";
import {SwitchProps} from "@mui/material/Switch";
import {useThemeModeDispatch} from "../../components/ThemeProvider";
import useCheckLogin from "../../hooks/use-check-login";
import useUserData from "../../hooks/use-user-data";


const ThemeSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function Profile() {
  const aboutText = 'درباره ما';
  const rulesText = 'شرایط و قوانین استفاده';
  const switchThemeText = 'حالت شب';
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const themeModeDispatcher = useThemeModeDispatch();
  const { isLoggedIn, logout } = useCheckLogin();
  const { username, loading, watchList } = useUserData();


  // @ts-ignore
  const [mode, setMode] = useState(() => {
    return localStorage.getItem('theme') ?? 'light';
  });

  
  const handleThemeSwitchClick = (e: any) => {
    let thm = e.target.checked ? 'dark' : 'light';
    localStorage.setItem('theme', thm);
    setMode(thm);
    themeModeDispatcher(thm);
  }

  const handleBackButtonClick = () => {
    navigate('/');
  }
  const handleLogoutClick = () => {
    logout();
  };
  const handleRedirectLogin = () => {
    navigate('/login');
  };

  return (
    <Paper className={'profile-page'} elevation={0} sx={{ pt: 0 }}>
      <Container maxWidth={false} sx={{ px: '0!important', position: 'relative', minHeight: '100vh' }}>
        <Grid container>
          <Grid item xs={12}
            sx={{
              textAlign: 'center',
              mb: 2,
              mt: 0,
              direction: 'ltr',
              display: 'flex',
            }}
          >
            <Box width={'43.5px'} height={'47px'}></Box>
            <Box
              sx={{
                fontSize: '1.8rem',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Box
                component={'img'}
                sx={{ width: '135px!important' }}
                src={theme.palette.mode === 'dark' ? '/logo-dark.svg' : '/logo.svg'}
              />
            </Box>
            <Box>
              <IconButton
                onClick={handleBackButtonClick}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <EastIcon fontSize={"large"} />
              </IconButton>
            </Box>
          </Grid>

          {/*<Grid
            mb={2}
            item
            xs={12}
          >
            <Box
              sx={{
                mx: 2,
                marginBottom: '16px',
              }}
            >


              {isLoggedIn === false ? (

                <Button
                  size="large"
                  onClick={handleRedirectLogin}
                  sx={{
                    borderRadius: '12px',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    width: '100%',
                    fontSize: '14px',
                    fontWeight: '500',
                    paddingY: '12px',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    }
                  }}
                >
                  ثبت نام و ورود
                </Button>
              ) : isLoggedIn === true ? (

                <Box
                  sx={{
                    p: 1,
                    borderRadius: '12px',
                    borderWidth: '2px',
                    borderColor: (theme) => theme.palette.divider,
                    borderStyle: 'solid',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent='space-between'
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                      />
                      <Box
                        component='span'
                        sx={{
                          fontSize: '14px',
                          fontWeight: '500',
                          marginRight: '5px'
                        }}
                      >
                        {!loading ? (
                          <span>
                            {username}
                          </span>
                        ) : (
                          <Skeleton animation="wave" width="100px" />
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Button
                        onClick={handleLogoutClick}
                        size="large"
                        sx={{
                          borderRadius: '12px',
                          backgroundColor: 'primary.main',
                          color: 'white',
                          width: '100%',
                          fontSize: '14px',
                          '&:hover': {
                            backgroundColor: 'primary.dark',
                          }
                        }}
                      >
                        خروج از حساب
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              ) : (

                <Skeleton animation="wave" width='100%' height={60} ></Skeleton>
              )}
            </Box>
          </Grid>*/}

          {/* Skelton */}

          {/* EndSkelton */}



          <Box
            sx={{
              mx: 2,
              width: '100%',
            }}
          >
          </Box>

          <Grid item xs={12}>
            <Box
              sx={{
                mx: 2,
                borderRadius: '12px',
                borderWidth: '2px',
                borderColor: (theme) => theme.palette.divider,
                borderStyle: 'solid',
              }}
            >
              <List dense>
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  sx={{
                    display: 'flex',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.background.default,
                      color: (theme) => theme.palette.text.primary
                    }
                  }}
                  component={Link}
                  to={"/terms"}
                >
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    <TermsIcon sx={{ fontSize: 24 }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      flexGrow: 1,
                      direction: 'rtl',
                      textAlign: 'right',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      '& .MuiListItemText-primary': {
                        fontSize: '1.1rem',
                        fontWeight: '500',
                      },
                    }}
                    primary={rulesText}
                    secondary={(
                      <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
                    )}
                  />
                </ListItemButton>
                <Divider sx={{ mx: 0 }} variant="inset" component="li" />
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  sx={{
                    display: 'flex',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.background.default,
                      color: (theme) => theme.palette.text.primary
                    }
                  }}
                  component={Link}
                  to={"/about"}
                >
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    <AboutUsIcon sx={{ fontSize: 24 }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      flexGrow: 1,
                      direction: 'rtl',
                      textAlign: 'right',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      '& .MuiListItemText-primary': {
                        fontSize: '1.1rem',
                        fontWeight: '500',
                      },
                    }}
                    primary={aboutText}
                    secondary={(
                      <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
                    )}
                  />
                </ListItemButton>
                <Divider sx={{ mx: 0 }} variant="inset" component="li" />
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  sx={{
                    display: 'flex',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.background.default,
                      color: (theme) => theme.palette.text.primary
                    }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    <SwitchThemeIcon sx={{ fontSize: 24 }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      flexGrow: 1,
                      direction: 'rtl',
                      textAlign: 'right',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      '& .MuiListItemText-primary': {
                        fontSize: '1.1rem',
                        fontWeight: '500',
                      },
                    }}
                    primary={switchThemeText}
                    secondary={(
                      <ThemeSwitch onChange={handleThemeSwitchClick} checked={mode === 'dark'} />
                    )}
                  />
                </ListItemButton>
                <Divider sx={{ mx: 0 }} variant="inset" component="li" />
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  sx={{
                    display: 'flex',
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.background.default,
                    }
                  }}
                  onClick={() => window.open('https://t.me/ramzarznews_support', '_blank')}
                >
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    <TelegramIcon sx={{ fontSize: 24 }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      flexGrow: 1,
                      direction: 'rtl',
                      textAlign: 'right',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      '& .MuiListItemText-primary': {
                        fontSize: '1.1rem',
                        fontWeight: '500',
                      },
                    }}
                    primary={'پشتیبانی تلگرام'}
                    secondary={(
                      <AttachIcon sx={{ fontSize: 32 }} />
                    )}
                  />
                </ListItemButton>
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ px: 3, position: 'absolute', bottom: 0, display: 'block', width: '100%' }}>
            <Stack direction="row" justifyContent="center">
              <IconButton
                aria-label="telegram"
                href="https://t.me/ramzarznews_support"
                target="_blank"
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.light,
                  '&:active': {
                    backgroundColor: (theme) => theme.palette.primary.light,
                  },
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.light,
                  }
                }}
              >
                <ThemedTelegramIcon />
              </IconButton>
              <IconButton sx={{
                mx: 1,
                backgroundColor: (theme) => theme.palette.primary.light,
                '&:active': {
                  backgroundColor: (theme) => theme.palette.primary.light,
                },
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.primary.light,
                }
              }}
                aria-label="instagram"
                href="https://instagram.com/ramzarz_news"
                target="_blank"
              >
                <InstagramIcon />
              </IconButton>
              <IconButton aria-label="aparat"
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.light,
                  '&:active': {
                    backgroundColor: (theme) => theme.palette.primary.light,
                  },
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.light,
                  }
                }}
                href="https://www.aparat.com/ramzarznews"
                target="_blank"
              >
                <AparatIcon />
              </IconButton>
            </Stack>
            <Box textAlign="center" sx={{ mt: 1 }}>v3.4.0</Box>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import {useState} from "react";
import {darken} from '@mui/system';
import useCheckLogin from "../../hooks/use-check-login";
import axios from "axios";

export default function Login() {
    const navigate = useNavigate();
    const theme = useTheme();
    const [valid, setValid] = useState(false)
    const [error, setError] = useState(false)
    const [contactInfo, setContactInfo] = useState('')
    const [typeInput, setTypeInput] = useState('')
    const { isLoggedIn, login } = useCheckLogin();


    const colorDefaultLogin = theme.palette.mode === 'light' ?
        darken(theme.palette.secondary.light, 0.1) : darken(theme.palette.secondary.light, 0.6);

    const handleBackButtonClick = () => {
        navigate('/');
    }
    const validateInput = (input: any) => {
        let isValid = false;
        if (/^\d/.test(input)) {
            isValid = /^09\d{9}$/.test(input);
            setTypeInput('mobile');

        } else {
            isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(input);
            setTypeInput('email');

        }

        setValid(isValid);
        setError(!isValid);
        setContactInfo(input);

    };

    const generateToken = () => {
        return [...Array(30)]
            .map(() => Math.random().toString(36)[2])
            .join('');
    };
    const handleLoginClick = () => {
        if (valid) {
          axios.post('https://appapi.ramzarz.news/api/v1/login', {
            username: contactInfo
          }).then((response) => {
            navigate('/otp', { state: { contactInfo, typeInput, trackid: response.data.trackid } });
          }).finally(() => {

          })
        }
    };
    return (
        <Container disableGutters maxWidth={false} sx={{ px: 1, mt: 4 }} >
            <Card variant="outlined"
                sx={{
                    minWidth: 275,
                    border: '0px'
                }}>
                <CardHeader
                    sx={{
                        marginTop: '0px',
                        paddingRight: '0px',
                        paddingTop: '0px',
                        '& .MuiCardHeader-avatar': {
                            marginRight: '0px',
                        },
                        '& .MuiCardHeader-title': {
                            fontSize: '15px',
                            textAlign: 'center'
                        },
                        avatar: {
                            marginRight: '0px'
                        }
                    }}
                    avatar={
                        <IconButton
                            sx={{ marginRight: '0px !important' }}
                            onClick={handleBackButtonClick}
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <EastIcon fontSize={"large"} />
                        </IconButton>
                    }
                    title={`ثبت نام / ورود`}

                />
                <CardContent
                    sx={{
                        paddingBottom: '16px !important',
                        borderRadius: '10px',
                        border: `1px solid ${colorDefaultLogin}`,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 2,
                            mb: 4

                        }}
                    >
                        <Box
                            component="img"
                            sx={{ width: '128px!important', marginRight: 0, }}
                            src={theme.palette.mode === 'dark' ? '/logo-dark.svg' : '/logo.svg'}
                        />
                    </Box>

                    <Typography>سلام!</Typography>
                    <Typography
                        sx={{
                            mb: 2,
                            mt: 1
                        }}
                    > لطفا شماره موبایل یا ایمیل خود را وارد کنید. </Typography>

                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            type='input'
                            error={error}
                            helperText={error ? "لطفا شماره موبایل یا ایمیل خود را به صورت صحیح وارد نمایید" : ''}
                            sx={{
                                mt: 2,
                                mb: 3,
                                '& .MuiOutlinedInput-root': {

                                    borderRadius: '10px',
                                },
                                '& .MuiInputBase-input': {
                                    paddingRight: '0px',
                                    textAlign: 'left',
                                    direction: 'ltr'
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    textAlign: 'right',
                                    mr: 2
                                }, '& .MuiFormHelperText-root': {
                                    textAlign: 'right',
                                    direction: 'rtl',
                                },
                            }}
                            fullWidth
                            placeholder="شماره موبایل / ایمیل"
                            variant="outlined"
                            onChange={(e) => validateInput(e.target.value)}

                        />
                        <Button
                            disableElevation
                            size='large'
                            variant="contained"
                            disabled={!valid}
                            onClick={handleLoginClick}
                            sx={{
                                width: '100%',
                                borderRadius: '10px',
                                py: 1,
                                color: theme.palette.mode === 'dark' ? 'black' : 'white',
                            }}

                        >
                            ورود
                        </Button>
                        <Divider
                            sx={{
                                mt: 3,
                                mb: 2,
                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                            }}
                        >
                            یا
                        </Divider>
                        <Button
                            size='large'
                            variant="contained"
                            startIcon={

                                <svg
                                    width="20px"
                                    height="20px"
                                    style={{
                                        marginLeft: '10px'
                                    }}
                                    viewBox="-3 0 262 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4" /><path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853" /><path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05" /><path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EB4335" />
                                </svg>
                            }
                            sx={{
                                py: '8px',
                                width: '100%',
                                borderRadius: '10px',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                border: `1px solid ${colorDefaultLogin}`,
                                color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                },

                            }}
                        >
                            ورود با گوگل
                        </Button>
                        <CardActions
                            sx={{
                                paddingBottom: '0px',
                                marginTop: '18px',
                                display: 'block',
                            }}
                        >
                            <span>ورود شما به معنای پذیرش </span>
                            <Link
                                href='/terms'
                                sx={{
                                    color: 'primary',
                                    textDecoration: 'none',
                                    mx: 0.25,
                                    '&:hover': {
                                        textDecoration: 'none',
                                    },
                                }}
                            >
                                شرایط و قوانین رمزارز نیوز
                            </Link>
                            <span> است </span>
                        </CardActions>
                    </Box>

                </CardContent>
            </Card>



        </Container >
    )
}

import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Button, ButtonProps, Container, darken, Grid, styled, Typography, useTheme} from '@mui/material';
import axios from 'axios';
import Loading from "../Loading";
import {Currency} from "./types";
import CurrencyHead from "./CurrencyHead";
import {Virtuoso} from 'react-virtuoso';
import CurrencyRow from "./CurrencyRow";
import CurrencyRowPlaceHolder from "./CurrencyRowPlaceHolder";
import {grey} from "@mui/material/colors";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useWatchListCoins from "../../hooks/use-watch-list-coins";
import {useSearchParams} from "react-router-dom";
import BottomDrawer from "../BottomDrawer";
import StarIcon from '@mui/icons-material/Star';
import EmptySearchResults from "../EmptySearchResults/EmptySearchResults";

const WATCHING_TEXT = 'تحت نظر';
const ALL_CURRENCIES_TEXT = 'همه ارزها';
const transformCurrencies = (data: any): Currency[] => {
  if (!data || !data.data || !data.data.cryptoCurrencyList) {
    return [];
  }
  return data.data.cryptoCurrencyList.map((d: any) => {
    let p = d.split('/');
    return {
      cmcId: p[0],
      cmcRank: p[1],
      name: p[2],
      symbol: p[3],
      slug: p[4],
      percentChange24h: p[5],
      percentChange30d: p[6],
      price: p[7],
      price_toman: p[8],
    };
  })
}

const CHANGES_LIST = [
  {
    id: '1h',
    slug: '1h',
    title: '۱ ساعته'
  },
  {
    id: '24h',
    slug: '24h',
    title: '۲۴ ساعته'
  },
  {
    id: '7d',
    slug: '7d',
    title: ' ۷ روزه'
  },
  {
    id: '1m',
    slug: '1m',
    title: '۱ ماهه'
  },
  {
    id: '3m',
    slug: '3m',
    title: '۳ ماهه'
  }
];
const transformTags = (data: any): TagInterface[] => {
  if (!data) {
    return [];
  }

  return data.map((t: any) => ({
    id: t.slug,
    title: t.fa_name,
    description: t.name
  }));
}

const AddToWatchListGuide = () => {
  const theme = useTheme();
  const buttonBackgroundColor = theme.palette.mode === 'dark' ? 'transparent' : 'transparent';
  const iconColor = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';

  return (
    <Box sx={{
      mt: 3,
      textAlign: 'center',
      direction: 'rtl',
    }}>
      <Typography
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        جهت اضافه کردن ارز به لیست تحت نظر، از صفحه داخلی ارزها، روی

        <Button
          disabled
          sx={{
            width: '117px',
            paddingLeft: '2px',
            '& .MuiButton-endIcon': {
              marginLeft: '0px',
              marginRight: '4px',
            },
            backgroundColor: buttonBackgroundColor,
            border: '1px solid gray',
            color: 'inherit',
            '&:disabled': {
              backgroundColor: buttonBackgroundColor,
              color: 'inherit',
              border: '1px solid gray',
            },
          }}
          variant={'outlined'}
          size={'small'}
          endIcon={
            <StarOutlineIcon sx={{ color: iconColor }} fontSize={'large'} />
          }
        >
          افزودن به تحت نظر
        </Button>

        ضربه بزنید
      </Typography>
    </Box>
  );
}
function mergeCurrencies<Type extends Currency>(currentCurrencies: Type[], newCurrencies: Type[]): Type[] {
  return currentCurrencies.concat(newCurrencies.filter((crypto) => {
    let alreadyExists = false;
    for (let i = 0; i < currentCurrencies.length; i++) {
      if (currentCurrencies[i].cmcId === crypto.cmcId) {
        alreadyExists = true
        break;
      }
    }

    return !alreadyExists;
  }))
}

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.mode === 'light' ?
    'white' : grey[900],
  borderColor: darken(theme.palette.divider, 0.1),
  borderWidth: '1px',
  '&:hover': {
    borderColor: darken(theme.palette.divider, 0.1),
    backgroundColor: theme.palette.mode === 'light' ?
      grey[50] : grey[900],
  },
  textTransform: 'none'
}));

const SelectTagButton = (props: any) => {
  return (
    <StyledButton
      fullWidth
      variant={'outlined'}
      endIcon={<KeyboardArrowDownIcon />}
      {...props}
    >{props.children}</StyledButton>
  );
}

const SelectChangeButton = (props: any) => {
  return (
    <StyledButton
      fullWidth
      variant={'outlined'}
      endIcon={<KeyboardArrowDownIcon />}
      {...props}
    >{props.children}</StyledButton>
  );
}


const PER_PAGE = 40;

const Empty = () => (<></>);

interface TagInterface {
  id: string;
  title: string;
  description?: string;
}

export default function CurrenciesTable() {
  const [loading, setLoading] = useState<boolean>(false)
  const [finished, setFinished] = useState<boolean>(false)
  const [wsUrl, setWsUrl] = useState<string>("")
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [updates, setUpdates] = useState<any>({});
  const [assets, setAssets] = useState("ALL");
  const [sort, setSort] = useState<'cmcRank' | 'price' | 'percentChange24h'>('cmcRank');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [nextPage, setNextPage] = useState<number>(1);
  const [buyButtonStatus, setBuyButtonStatus] = useState<0 | 1>(0);
  const [totalCount, setTotalCount] = useState(0);
  const [tags, setTags] = useState<Array<TagInterface>>([]);
  const [selectedTag, setSelectedTag] = useState<TagInterface | null>({
    id: 'all',
    description: 'همه',
    title: 'همه ارزها'
  });
  const [changes, setChanges] = useState<TagInterface | null>(null);
  const [tagQuery, setTagQuery] = useState('');

  const {
    ids,
  } = useWatchListCoins();
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {

  }, [searchParams, ids]);

  useEffect(() => {
    axios.get('https://appapi.ramzarz.news/api/settings/buy-button-status')
      .then(response => {
        if (response.data.status === "success") {
          setBuyButtonStatus(response.data.data.buy_button_status_in_list);
        }
      })
      .catch(err => console.error('Error fetching buy button status:', err));
  }, []);

  useEffect(() => {
    setLoading(true)
    let watchingIds: Array<number> = [];
    let tag = '';
    let ch = '24h';

    if (searchParams.get('watching') === '1') {
      if (ids.length > 0) {
        watchingIds = ids;
      } else {
        watchingIds = [100000]
      }

    }
    if (!!searchParams.get('tag')) {
      tag = searchParams.get('tag') ?? 'all';
    }
    if (!!searchParams.get('changes')) {
      ch = searchParams.get('changes') ?? '24h';
    }

    axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/currencies-table?page=${nextPage}&limit=${PER_PAGE}&sort=${sort}&order=${order}&ids=${watchingIds}&tag=${tag}&changes=${ch}`)
      .then(response => response.data)
      .then((data) => {
        if(searchParams.get('watching') === '1' || nextPage === 1){
          setCurrencies(transformCurrencies(data))
        } else {
          setCurrencies(oldCurrencies => mergeCurrencies(oldCurrencies, transformCurrencies(data)))
        }

        setTotalCount(data.data.totalCount ?? 0)

      }).catch(function (err) {
        if (err.code === "ERR_BAD_REQUEST" && err.response.status === 400 && err.response.data.code === "rest_post_invalid_page_number") {
          setFinished(true);
        }

      }).finally(() => {
        setLoading(false)
      });
  }, [nextPage, sort, order, searchParams, ids, changes]);

  useEffect(() => {
    setWsUrl(`wss://ws.coincap.io/prices?assets=${assets}`);
  }, [assets])

  useEffect(() => {
    setAssets(currencies.map((currency) => currency.slug).join(','));
  }, [currencies])

  useEffect(() => {
    if (!!wsUrl) {
      const ws = new WebSocket(wsUrl);
      ws.onmessage = (event) => {
        if (!loading) {
          setUpdates(JSON.parse(event.data))
        }
      }
      return () => {
        ws.close();
      }
    }
  }, [wsUrl, loading]);

  useEffect(() => {
    setCurrencies(oldCurrencies => oldCurrencies.map((currency) => {
      if (updates[currency.name.toLowerCase()] !== undefined) {
        currency.price = updates[currency.name.toLowerCase()];
      }
      return currency;
    }))

  }, [updates]);

  useEffect(() => {
    axios.get('https://appapi.ramzarz.news/api/cryptocurrency/tags')
      .then(response => response.data)
      .then((data) => {
        setTags(transformTags(data));
      });
  }, []);

  useEffect(() => {
    const tagSlug = !!searchParams.get('tag') ? searchParams.get('tag') : 'all';

    const selected = tags.filter((t, i) => {
      return t.id === tagSlug
    });
    if (selected.length) {
      setSelectedTag(selected[0])
    }
  }, [tags]);

  useEffect(() => {
    const tagSlug = !!searchParams.get('changes') ? searchParams.get('changes') : '24h';

    const selected = CHANGES_LIST.filter((t, i) => {
      return t.id === tagSlug
    });
    if (selected.length) {
      setChanges(selected[0])
    }
  }, [searchParams]);

  function handleSortChange(newVal: 'cmcRank' | 'price' | 'percentChange24h') {
    setCurrencies([])
    setNextPage(1)
    setSort(newVal);
  }

  function handleOrderChange(newVal: 'asc' | 'desc') {
    setCurrencies([])
    setNextPage(1)
    setOrder(newVal);
  }

  function handleLoadMore() {
    if (totalCount > nextPage * PER_PAGE) {
      setNextPage(c => c + 1);
    }
  }

  const handleWatchListButtonClick = () => {
    searchParams.set('watching', '1');
    setSearchParams(searchParams);

    setCurrencies([]);
    setNextPage(1);
  }

  const handleSetSelectedTag = (tag: TagInterface) => {
    setSelectedTag(tag);

    searchParams.set('tag', tag.id);
    setSearchParams(searchParams);
    setCurrencies([]);
    setNextPage(1);
  }

  const handleSetSelectedChange = (changes: TagInterface) => {
    setChanges(changes);

    searchParams.set('changes', changes.id);
    setSearchParams(searchParams);

    setCurrencies([]);
    setNextPage(1);
  }

  const handleSearchInTags = (query: string) => {
    setTagQuery(query);
  }

  return (
    <Container className="currencies-table custom-font-size" sx={{ direction: 'ltr', paddingTop: '0px!important' }} disableGutters maxWidth={false}>
      <Grid container py={1} px={{ xs: 1.5 }} spacing={{ xs: 2.5, sm: 3.5 }}>
        <Grid item xs={4}>
          <StyledButton
            fullWidth
            variant={'outlined'}
            color={'primary'}
            endIcon={searchParams.get('watching') === '1' ? <StarIcon /> : <StarOutlineIcon />}
             onClick={searchParams.get('watching') === '1' ?  () => {} : handleWatchListButtonClick}
          >
            <Typography noWrap component={'span'} fontSize={{ xs: 'smaller', sm: 'normal' }}>{WATCHING_TEXT}</Typography>
          </StyledButton>
        </Grid>
        <Grid item xs={4}>
          <BottomDrawer
            items={tagQuery ? tags.filter((t) => t.id.toLowerCase().indexOf(tagQuery.toLowerCase()) !== -1 || t.title.toLowerCase().indexOf(tagQuery.toLowerCase()) !== -1) : tags}
            selectedItem={selectedTag}
            DrawerButton={SelectTagButton}
            onSelect={handleSetSelectedTag}
            onSearch={handleSearchInTags}
          />
        </Grid>
        <Grid item xs={4}>
          <BottomDrawer
            popoverTitle={'نوسان ٪'}
            items={CHANGES_LIST}
            selectedItem={changes}
            DrawerButton={SelectChangeButton}
            onSelect={handleSetSelectedChange}
            disableSearch
          />
        </Grid>
      </Grid>
      <CurrencyHead
        sort={sort}
        order={order}
        onSortChange={handleSortChange}
        onOrderChange={handleOrderChange}
        buyButtonStatus={buyButtonStatus}
      />
      {/*<InfiniteScroll
        dataLength={currencies.length}
        next={handleLoadMore}
        hasMore={true}
        loader={<Loading/>}
        endMessage={
          <p style={{textAlign: 'center'}}>
            <b>اطلاعات بیشتر برای نمایش وجود ندارد</b>
          </p>
        }
        style={{overflow: 'unset'}}
      >
        <Currencies currencies={currencies} />
      </InfiniteScroll>*/}
      {searchParams.get('watching') === '1' && ids.length === 0 && (
        <>
          <EmptySearchResults message="هیچ ارزی در لیست تحت نظر یافت نشد!" />
          <AddToWatchListGuide />
        </>
      )}
      {
        (searchParams.get('watching') != '1' || (searchParams.get('watching') === '1' && ids.length > 0)) && (
          <Virtuoso
            useWindowScroll
            data={currencies}
            style={{ height: window.innerHeight - 49 - 59 }}
            endReached={handleLoadMore}
            itemContent={(index, currency) => {
              return (
                <CurrencyRow key={currency.cmcId} currency={currency} buyButtonStatus={buyButtonStatus} />
              );
            }}
            components={{
              Footer: loading ? Loading : Empty,
              ScrollSeekPlaceholder: CurrencyRowPlaceHolder
            }}
            scrollSeekConfiguration={{
              enter: (velocity) => Math.abs(velocity) > 1000,
              exit: (velocity) => {
                return Math.abs(velocity) < 10;
              }
            }}
          />
        )
      }
    </Container>
  );
}

import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Box, Container, Divider, Grid, Paper, Skeleton, Stack, Typography} from "@mui/material";
import LinkButton from "../LinkButton";
import axios from "axios";
import Loading from "../Loading";
import {PostInterface} from "../../types";
import PostItem from "../PostItem";
import useIframeModal from "../../hooks/use-iframe-modal";
import {useParams} from "react-router-dom";
import IframeModal from "../IframeModal";
import {Virtuoso} from 'react-virtuoso';

interface PostsListInterface {
  url: string;
  type: 'article' | 'news';
  title: string;
  singlePath: string,
  icon?: any,
}

function Header(props: { title: string, icon?: any }) {
  const {
    title,
    icon
  } = props;
  return (
    <Grid container className={'posts-title'}>
      <Grid item>
        <Typography sx={{ my: 1 }}>
          {props.icon}
          <Box component='span' sx={{ verticalAlign: 'top' }}>{title}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
}
const PostRowSkeleton = () => {

  return (
    <Paper className={'post-paper'} elevation={0}>
      <Grid container sx={{ py: 1 }}>
        <Grid item xs={3} className={'post-media'}>
          <LinkButton
          >
            <Skeleton
              variant="rectangular"
              sx={{
                width: '70px',
                height: '70px',
                maxHeight: '70px',
                borderRadius: '3px'
              }} />

          </LinkButton>
        </Grid>
        <Grid item xs={9} >
          <Stack>
            <Box>
              <Skeleton width="60%" />
            </Box>
            <Box>
              <LinkButton
              >
                <Skeleton width="60%" />
              </LinkButton>
            </Box>

          </Stack>
        </Grid>
      </Grid>
      <Divider />
    </Paper>
  );
}

export default function Posts(props: PostsListInterface) {
  const [posts, setPosts] = useState<PostInterface[]>([])
  const [finished, setFinished] = useState<boolean>(false)
  const { src, setSlug } = useIframeModal();
  const { slug } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const Empty = () => (<></>);

  useEffect(() => {
    setSlug(slug ?? null);
  }, [slug]);


  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = useCallback(() => {
    const page = Math.floor(posts.length / 20) + 1;
    axios.get(`${props.url}&page=${page}`)
      .then(response => response.data)
      .then((data) => {
        setPosts(posts.concat(data))
      }).catch(function (err) {
        setFinished(true);
        if (err.code === "ERR_BAD_REQUEST" && err.response.status === 400 && err.response.data.code === "rest_post_invalid_page_number") {
          setFinished(true);
        }
      }).finally(() => {
      });
  }, [posts])
  return (
    <Container disableGutters maxWidth={false} sx={{ px: 1 }}>
      <Header title={props.title} icon={props.icon} />
      <Divider />

      <Virtuoso
        useWindowScroll
        data={posts}
        itemContent={(index, post) => {
          return (
            <PostItem key={index} post={post} type={props.type} singlePath={props.singlePath}/>
          );
        }}

        components={{
          Footer: loading ? Loading : Empty,
          ScrollSeekPlaceholder: PostRowSkeleton
        }}
        scrollSeekConfiguration={{
          enter: (velocity) => Math.abs(velocity) > 1000,
          exit: (velocity) => {
            return Math.abs(velocity) < 10;
          }
        }}
        endReached={getPosts}
      />

      <IframeModal src={src} />
    </Container>
  );
}

import {Box, Button, Chip, Container, Grid, IconButton, Paper, Typography, useTheme} from '@mui/material';
import {blue} from '@mui/material/colors';
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import Loading from "../../components/Loading";
import DollarPrice from "../../components/DollarPrice";
import Toman from "../../components/Toman";
import Blinker from "../../components/Blinker";
import TradingViewAdvancedChart from "../../components/TradingViewAdvancedChart";
import TradingViewTechnicalChart from "../../components/TradingViewTechnicalChart";
import TimeSeriesChart from "../../components/TimeSeriesChart";
import EastIcon from '@mui/icons-material/East';
import ColorfulChip from "../../components/ColorfulChip";
import SingleCurrencyChartButtons from "../../components/SingleCurrencyChartButtons";
import TimeSeriesChartButtons from "../../components/TimeSeriesChartButtons";
import CartIcon from '../../components/Icons/CartIcon';
import Stats from "./Stats";
import {CurrencyInterface} from "./types";
import useWatchListCoins from "../../hooks/use-watch-list-coins";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';

const BUY_TEXT = 'خرید';


const EMPTY_CURRENCY: CurrencyInterface = {
  cmcId: '',
  cmcRank: '',
  slug: '',
  name: '',
  nameFa: '',
  symbol: '',
  price: '',
  price_toman: '',
  percentChange1h: '',
  percentChange24h: '',
  percentChange7d: '',
  percentChange30d: '',
  percentChange60d: '',
  percentChange90d: '',
  percentChange180d: '',
  maxSupply: '',
  circulatingSupply: '',
  ath: '',
  atl: '',
  high24h: '',
  low24h: '',
  volume24h: '',
  marketCap: '',
  dominance: '',
  raise: 0,
  buy_button_status: 0,
  buy_button_link: '',
};

const ADD_TO_WATCH_LIST_TEXT = 'افزودن به تحت نظر';
const REMOVE_FROM_WATCH_LIST_TEXT = 'حذف از تحت نظر';

export default function SingleCurrency() {
  const iframeRef = useRef<HTMLDivElement>(null);
  const [currency, setCurrency] = useState<CurrencyInterface>(EMPTY_CURRENCY);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [wsUrl, setWsUrl] = useState<string>("");
  const { slug } = useParams();
  const [duration, setDuration] = useState<number>(1);
  const [chartType, setChartType] = useState<'bar' | 'technical' | 'advanced'>('bar');
  const [iframeWidgetBaseLink, setIframeWidgetBaseLink] = useState<string>("");
  const [iframeWidgetStatus, setIframeWidgetStatus] = useState<number>(0);
  const theme = useTheme();
  const location = useLocation();
  const lastHash = useRef('');
  const {
    ids,
    addToWatchList,
    removeFromWatchList,
    isWatchListed
  } = useWatchListCoins();

  useEffect(() => {
    if (!!slug) {
      setWsUrl(`wss://ws.coincap.io/prices?assets=${slug}`);
    }
  }, [slug])

  useEffect(() => {
    /*if (location.hash.indexOf('buy-currency') !== -1 && iframeWidgetStatus === 1 && iframeRef.current) {
      if(!!document && !!document.getElementById('buy-currency')){
        // @ts-ignore
        window.scrollTo(0, document.getElementById('buy-currency').offsetTop)
      }
    }*/
  }, [iframeWidgetStatus, iframeRef]);

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 500);
    }
  }, [location]);

  useEffect(() => {
    if (!!slug) {
      loadCurrency(slug)
    }
  }, [slug]);
  useEffect(() => {

    if (!!wsUrl && loaded) {
      const ws = new WebSocket(wsUrl);
      ws.onmessage = (event) => {
        try {
          const updates = JSON.parse(event.data);

          let currentCurrency = Object.assign({}, currency);
          Object.keys(updates).forEach((currencyName) => {
            if (currentCurrency.name.toLowerCase() === currencyName.toLowerCase()) {
              currentCurrency.price_toman = (parseFloat(updates[currencyName]) * (parseFloat(currentCurrency.price_toman) / parseFloat(currentCurrency.price))).toString()

              currentCurrency.price = updates[currencyName];
            }
          })

          setCurrency(currentCurrency);

        } catch (err) {
        }


      }
      return () => {
        ws.close();
      }
    }
  }, [wsUrl, loaded]);


  useEffect(() => {
    axios.get('https://appapi.ramzarz.news/api/settings/iframe-widget-settings')
      .then(response => {
        const { iframe_widget_status, iframe_widget_base_link } = response.data.data;
        setIframeWidgetStatus(iframe_widget_status);
        setIframeWidgetBaseLink(`${iframe_widget_base_link}${currency.symbol.toLowerCase()}`);
      }).catch(err => {
      });
  }, []);

  function loadCurrency(slug: string) {
    setLoading(true);
    axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/single/${slug.toLowerCase()}`).then(response => response.data)
      .then((data) => {
        setCurrency(data)
        setLoaded(true);
      }).catch((err) => {

      }).finally(() => setLoading(false))
  }

  return (
    loading ? <Loading /> : (
      !!currency.cmcId ?
        <Paper className={'single-currency-paper'}
          sx={{ px: 0, mx: 0, borderRadius: 0, pb: currency.buy_button_status == 1 ? '80px' : '0px' }} elevation={0}>
          <Container maxWidth={false}>
            <Grid container>
              <Grid key={'single-currency-media'} className={'single-currency-media'} item xs={12}
                sx={{
                  textAlign: 'center',
                  mb: 2,
                  mt: 1,
                  direction: 'ltr',
                  display: 'flex',
                }}
              >
                <Box sx={{
                  fontSize: '1.8rem',
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  gap: '4px',
                }}>
                  <Box component='img'
                    sx={{
                      verticalAlign: 'middle',
                      width: '48px',
                    }}
                    src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${currency.cmcId}.png`} />
                  <Typography component='span' fontWeight='700' variant='h3'>{currency.name}</Typography>
                  <Chip sx={{ borderRadius: '5px', backgroundColor: blue[100], color: blue[600] }}
                    variant='filled'
                    label={currency.symbol.toUpperCase()} size="small" color="info"
                  />
                </Box>
                <Box>
                  <IconButton
                    href={"/currencies"}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <EastIcon fontSize={"large"} />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box mb={1}>
                      <ColorfulChip value={Number(currency.percentChange24h)} suffix={'%'} />
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          width: '117px',
                          paddingLeft: '2px',
                          '& .MuiButton-endIcon': {
                            marginLeft: '0px',
                            marginRight: '4px',
                          },

                          '&:hover': {
                            backgroundColor: isWatchListed ( Number(currency.cmcId))
                            ? (theme.palette.mode === 'dark'
                              ? 'primary'   // dark & isWatchListed 
                              : 'secondary' // light & isWatchListed 
                            )
                            : (theme.palette.mode === 'light'
                              ? 'white'      // dark & !isWatchListed 
                              : 'transparent'     // light & !isWatchListed 
                            ),
                            border: isWatchListed(Number(currency.cmcId)) ? '1px solid primary' : '1px solid gray',
                          },

                          backgroundColor: isWatchListed ( Number(currency.cmcId))
                            ? (theme.palette.mode === 'dark'
                              ? 'primary'   // dark & isWatchListed 
                              : 'secondary' // light & isWatchListed 
                            )
                            : (theme.palette.mode === 'light'
                              ? 'white'      // dark & !isWatchListed 
                              : 'transparent'     // light & !isWatchListed 
                            ),
                          border: isWatchListed(Number(currency.cmcId)) ? '1px solid primary' : '1px solid gray',
                        }}
                        variant={'outlined'}
                        size={'small'}
                        onClick={() => isWatchListed(Number(currency.cmcId)) ? removeFromWatchList(Number(currency.cmcId)) : addToWatchList(Number(currency.cmcId))}
                        endIcon={
                          isWatchListed(Number(currency.cmcId))
                            ? (theme.palette.mode === 'dark'
                              ? <StarIcon color={'primary'} fontSize={'large'} />   // dark & isWatchListed 
                              : <StarIcon color={'primary'} fontSize={'large'} /> // light & isWatchListed 
                            )
                            : (theme.palette.mode === 'light'
                              ? <StarOutlineIcon sx={{ color: '#000000' }} fontSize={'large'} />     // dark & !isWatchListed 
                              : <StarOutlineIcon sx={{ color: '#ffffff' }} fontSize={'large'} />    // light & !isWatchListed 
                            )
                        }
                      >{isWatchListed(Number(currency.cmcId)) ? REMOVE_FROM_WATCH_LIST_TEXT : ADD_TO_WATCH_LIST_TEXT}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box key={'single-currency-usd'} className={'single-currency-usd'}>
                      <Typography textAlign='left' variant='h4' fontWeight='500' sx={{ my: 2 }}>
                        <Blinker mode={currency.raise}>
                          <DollarPrice price={currency.price} />
                        </Blinker>
                      </Typography>
                    </Box>
                    <Box key={'single-currency-toman'} className={'single-currency-toman'}>
                      <Typography className='iransans-fa-num' color={(theme) => theme.palette.text.secondary} textAlign='left' variant='h4' fontWeight='300' sx={{ my: 2 }}>
                        <Toman
                          irt={currency.price_toman}
                          usd={currency.price}
                          symbol='تومان'
                        />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>


              <Grid key={'chart-switch'} className={'chart-switch'} item my={2} xs={12}>
                <SingleCurrencyChartButtons
                  chartType={chartType}
                  onChange={(newChartType) => setChartType(newChartType)}
                />
              </Grid>
              <Grid key={'single-currency-chart-timeseries'}
                className={'single-currency-chart'}
                item
                xs={12}
                sx={{ mt: 2, display: chartType === 'bar' ? 'block' : 'none' }}>
                <TimeSeriesChart
                  theme={theme}
                  slug={currency.slug.toLowerCase()}
                  symbol={currency.symbol.toUpperCase()}
                  duration={duration} />
                <TimeSeriesChartButtons
                  duration={duration}
                  onChange={(newDue) => setDuration(newDue)}
                />
              </Grid>

              <Grid key={'single-currency-chart-advanced'} className={'single-currency-chart'} item xs={12}
                sx={{ display: chartType === 'advanced' ? 'flex' : 'none', width: '100%' }}>
                <TradingViewAdvancedChart
                  show={chartType === 'advanced'}
                  symbol={`MEXC:${currency.symbol.toUpperCase()}USDT`}
                />
              </Grid>
              <Grid key={'single-currency-chart-technical'} className={'single-currency-chart'} item xs={12}
                sx={{ mt: 2, display: chartType === 'technical' ? 'flex' : 'none', width: '100%' }}>
                <TradingViewTechnicalChart
                  show={chartType === 'technical'}
                  symbol={`MEXC:${currency.symbol.toUpperCase()}USDT`}
                />
              </Grid>
              {iframeWidgetStatus === 1 && (
                <Grid item xs={12} sx={{ mt: 5 }} >
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontWeight: '600',
                      fontSize: '1.1rem',
                      paddingBottom: '8px'
                    }}
                  >
                    خرید آنی {currency.nameFa}
                  </Typography>
                  <Box sx={{ textAlign: 'center' }} >
                    <iframe
                      src={`${iframeWidgetBaseLink}${currency.symbol.toLowerCase()}`}
                      height="360"
                      style={{ width: "100%", backgroundColor: 'whitesmoke', borderRadius: '20px' }}
                      title="Iframe Widget"
                      frameBorder="0"
                      allowFullScreen
                    />
                  </Box>
                </Grid>
              )}

              <Grid key={'single-currency-stats'} className={'single-currency-stats1'} item xs={12} sx={{ mt: 3 }}>
                <Stats currency={currency} />
              </Grid>
            </Grid>
          </Container>
          {currency.buy_button_status == 1 && (
            <Box className={'buy-button-box'} sx={{
              borderTopWidth: '2px',
              borderTopStyle: 'solid',
              borderTopColor: (theme) => theme.palette.divider,
              backgroundColor: (theme) => theme.palette.background.default,
              pt: '16px',
              pb: '16px',
              px: 2,
              width: '100%',
              position: 'fixed',
              bottom: '0',
              display: 'flex',
              justifyContent: 'center'
            }}>
              <Button
                className={'buy-button'}

                href={currency.buy_button_link} target={'_blank'} fullWidth
                startIcon={<CartIcon sx={{ ml: 1 }} color={'info'} />}
                sx={{
                  maxWidth: '450px',
                  backgroundColor: '#23b371',
                  textDecoration: 'none',
                  color: 'white',
                  borderRadius: '12px',
                  '&:hover': { color: 'white!important', backgroundColor: '#23b371' }
                }} color={'success'} size={'large'}
                variant={'contained'}>{`${BUY_TEXT} ${!!currency.nameFa ? currency.nameFa : currency.name}`}</Button>
            </Box>
          )}
        </Paper> : <Box></Box>
    )
  );
}

import {Button, ButtonProps, Stack, styled} from "@mui/material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BarChartIcon from "@mui/icons-material/BarChart";
import * as React from "react";

const StyledButton = styled(Button)<ButtonProps>(({theme}) => ({
  borderRadius: '32px',
  alignItems: 'end',
  padding: '4px 5px!important',
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
  },
  '&.MuiButton-text': {
    color: theme.palette.text.secondary,
  }
}));

export interface SingleCurrencyChartButtonsProps{
  chartType: 'bar' | 'advanced' | 'technical';
  onChange: (chartType: 'bar' | 'advanced' | 'technical') => unknown;
}
export default function SingleCurrencyChartButtons(props: SingleCurrencyChartButtonsProps){
  const {
    chartType,
    onChange
  } = props;
  return (
    <Stack
      className={'single-currency-chart-buttons'}
      sx={{
        bgcolor: (theme) => theme.palette.primary.light,
        borderRadius: '20px',
        py: 1
      }}
      direction={'row'}
      justifyContent={'space-around'}>
      <StyledButton
        variant={chartType === 'bar' ? 'contained' : 'text'}
        size={'small'}
        color={chartType === 'bar' ? 'secondary' : undefined}
        onClick={() => onChange('bar')}
        startIcon={<ShowChartIcon sx={{mr: 0, ml: '4px'}}/>}
      >
        نمودار خطی
      </StyledButton>
      <StyledButton
        variant={chartType === 'advanced' ? 'contained' : 'text'}
        size={'small'}
        color={chartType === 'advanced' ? 'secondary' : undefined}
        onClick={() => onChange('advanced')}
        startIcon={<TrendingUpIcon sx={{mr: 0, ml: '4px'}}/>}
      >
        نمودار شمعی
      </StyledButton>
      <StyledButton
        variant={chartType === 'technical' ? 'contained' : 'text'}
        size={'small'}
        color={chartType === 'technical' ? 'secondary' : undefined}
        onClick={() => onChange('technical')}
        startIcon={<BarChartIcon sx={{mr: 0, ml: '4px'}}/>}
      >
        نمودار عقربه ای
      </StyledButton>
    </Stack>
  );
}
import {useLocalStorage} from 'usehooks-ts';
import useUserData from './use-user-data';
import useCheckLogin from './use-check-login';

const useWatchListCoins = () => {
  const [watchlistCoins, setWatchlistCoins] = useLocalStorage<Array<number>>('watchlist_coins', []);
  const {
    watchList: serverWatchList,
    addToWatchList: serverAddToWatchList,
    removeFromWatchList: serverRemoveFromWatchList,
    isWatchListed: serverIsWatchListed,

  } = useUserData();
  
  const {
    isLoggedIn
  } = useCheckLogin();

  const addToWatchList = (id: number) => {
    const currentIds = watchlistCoins;
    // @ts-ignore
    if(!currentIds.includes(id)){
      // @ts-ignore
      setWatchlistCoins([...currentIds, id]);
    }
    
  }

  const removeFromWatchList = (id: number) => {
    const currentIds = watchlistCoins;
    setWatchlistCoins(
      currentIds.filter((oldId: number) => oldId != id)
    );
  }

  const isWatchListed = (id: number) => {
    // @ts-ignore
    return watchlistCoins.includes(id);
  }
  
  return {
    ids: isLoggedIn ? serverWatchList : watchlistCoins,
    addToWatchList: isLoggedIn ? serverAddToWatchList : addToWatchList, 
    removeFromWatchList: isLoggedIn ? serverRemoveFromWatchList : removeFromWatchList, 
    isWatchListed: isLoggedIn ? serverIsWatchListed: isWatchListed,
    
  };
}

export default useWatchListCoins;
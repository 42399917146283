import * as React from 'react';
import {styled} from '@mui/material/styles';
import Switch, {SwitchProps} from '@mui/material/Switch';

export interface LabeledSwitchProps extends SwitchProps{
  label1: string;
  label2: string;
}
const LabeledSwitch = styled((props: LabeledSwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))<LabeledSwitchProps>(({ theme, label1, label2 }) => ({
  width: 268,
  height: 48,
  padding: 2,
  fontSize: 'smaller',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    paddingLeft: 3,
    paddingTop: 2,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(130px)',
      color: '#000',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.light,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.light,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 128,
    height: 40,
    borderRadius: 24,
    backgroundColor: theme.palette.primary.main,
    '&::before': {
      content: `"${label2}"`,
      position: 'absolute',
      textAlign: 'center',
      color: '#000',
      width: '100%',
      height: '100%',
      top: 10,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    '&::before': {
      content: `"${label1}"`,
      position: 'absolute',
      color: '#000',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      top: 10,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 26,
    backgroundColor: theme.palette.primary.light,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    '&::before, &::after': {
      content: '""',
      color: theme.palette.text.disabled,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '50%',
      height: '50%'
    },
    '&::before': {
      content: `"${label2}"`,
      left: 12,
      textAlign: 'center'
    },
    '&::after': {
      content: `"${label1}"`,
      right: 12,
      textAlign: 'center'
    },
  },
}));

export default LabeledSwitch;
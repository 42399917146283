import * as React from "react";
import {useState} from "react";
import {Box, Skeleton} from "@mui/material";


interface MultiSrcImgProps {
  src: string;
  fallbackSrc?: string;
  defaultSrc?: string;
  size: string;
  variantType: "text" | "rectangular" | "rounded" | "circular"; 
}

export default function MultiSrcImg(props: MultiSrcImgProps) {
  const {
    src,
    fallbackSrc,
    defaultSrc,
    size,
    variantType
  } = props;

  const [isLoadedImage, setIsLoadedImage] = useState(false);

  const handleImageLoad = () => {
    setIsLoadedImage(true);
  };
  return (
    <>
      
      {!isLoadedImage && (
        <Skeleton
          animation="wave"
          variant={variantType}
          sx={{
            width: size,
            height: size
          }}
        />
      )}
      <Box
        component="img"
        src={src}
        onError={(event) => {
          event.currentTarget.onerror = () => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = fallbackSrc ?? defaultSrc!;
          }
          event.currentTarget.src = fallbackSrc ?? defaultSrc!;
        }}
        onLoad={handleImageLoad}
        sx={{
          paddingRight: '0px !important',
          width: size,
          height: size,
          display: isLoadedImage ? "block" : "none",
          borderRadius: variantType === "circular" ? "50%" : '4px',
        }}
      />
    </>
  );
}


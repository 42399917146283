import * as React from 'react';
import {useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material';
import {default as RootBottomNavigation} from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {Link, useLocation, useSearchParams} from "react-router-dom";
import {BottomNavigationIcon} from "./BottomNavigationIcon";
import bottomNavigationItems from "./BottomNavigationItems";

export default function BottomNavigation() {
  const [value, setValue] = React.useState(1000);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  useEffect(() => {
    if (location && location.pathname.startsWith("/terms")) {
      setValue(6);
    } else if (location && location.pathname.startsWith("/about")) {
      setValue(5);
    } else if (location && location.pathname.startsWith("/articles")) {
      setValue(2);
    } else if (location && location.pathname.startsWith("/news")) {
      setValue(2);
    } else if (location && location.pathname.startsWith("/blog")) {
      setValue(2);
    } else if (location && location.pathname.startsWith("/ideas")) {
      setValue(1);
    } else if (location && location.pathname.startsWith("/calculator")) {
      setValue(0);
    } else if (location && location.pathname.startsWith("/exchanges")) {
      setValue(3);
    } else {
      if (searchParams.get('watching') === '1') {
        setValue(1000);
      } else {
        setValue(0);
      }

    }
  }, [location, searchParams]);

  const isActiveMenu = (menuValue: number) => {
    if (menuValue === 0) {
      if (searchParams.get('watching') === '1') {
        return false;
      }
      return value === menuValue;
    } else {
      return value === menuValue;
    }
  }

  return (
    <Paper className={'bottom-nav-wrapper'} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: '1100!important' }} elevation={3}>
      <RootBottomNavigation
        sx={{
          height: '50px',
          '& .MuiButtonBase-root.MuiBottomNavigationAction-root': {
            textDecoration: 'none',
            color: (theme) => theme.palette.text.secondary
          },
          '& .MuiBottomNavigationAction-label.Mui-selected': {
            fontWeight: 600,
          },
          '& .MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected': {
            color: (theme) => theme.palette.primary.dark
          }
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {bottomNavigationItems.map((bottomNavigationItem) => {
          const isActive = isActiveMenu(bottomNavigationItem.value);

          return (
            <BottomNavigationAction
              key={bottomNavigationItem.name}
              component={Link}
              to={bottomNavigationItem.link}
              disableRipple
              label={bottomNavigationItem.label}
              icon={<BottomNavigationIcon icon={bottomNavigationItem.icon} active={isActive} />}
              sx={(theme) => ({
                '& .MuiBottomNavigationAction-label': {
                  color: isActive
                    ? theme.palette.primary.dark  
                    : theme.palette.text.primary
                }
              })}
              />
          );
        })}
      </RootBottomNavigation>
    </Paper>
  );
}

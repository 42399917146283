import * as React from "react";
import {useEffect, useRef, useState} from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import {Box, Theme} from "@mui/material";
import moment from 'jalali-moment';

function mapSymbol(symbol: string): string {
  if (symbol.toUpperCase() === 'TON') return 'TONCOIN';

  return symbol;
}
export default function TimeSeriesChart(props: { slug: string, symbol: string, duration: number, theme: Theme }) {
  const {
    slug,
    symbol,
    duration,
    theme,
  } = props;
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);

  const options: Highcharts.Options = {
    title: {
      text: undefined
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: true,
      title: {
        text: ''
      },
      gridLineColor: theme.palette.divider
    },
    chart: {
      backgroundColor: theme.palette.background.default,
      selectionMarkerFill: 'red',
      height: 380,
    },
    colorAxis: {
      gridLineColor: 'red',
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      area: {
        fillColor: 'transparent',
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },

    series: [{
      type: 'area',
      name: `${symbol} to USD`,
      lineColor: '#1ca0ed',
      lineWidth: 1,
      marker: {
        /*lineColor: '#FDB913',
        fillColor: '#fff',*/
      },
      data: data
    }],
    tooltip: {
      //backgroundColor: '#FDB913',
      borderRadius: 24,
      borderColor: '#fff',
      formatter: function () {
        return `<div><b>${moment(Number(this.x)*1000).format('jYYYY/jM/jD')}</b>&nbsp;&nbsp;&nbsp;<span>${moment(Number(this.x)*1000).format('HH:mm')}</span></div><br/><br/>
                <div><b>Price:</b>&nbsp;<span>${new Intl.NumberFormat('en-US', {maximumFractionDigits: Number(this.y) > 1 ? 2 : 5}).format(Number(this.y))}</span><span>$</span></div><br/><br/>
                <div><b>Date (Gregorian):</b>&nbsp;<span>${(new Date(Number(this.x)*1000)).toLocaleDateString()}</span></div>`;
      },
    }
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);


  useEffect(function () {
    let due = '1D';
    let limit = 288;
    if (duration === 1) {
      due = '1D'
      limit = 288
    } else if (duration === 7) {
      due = '7D'
      limit = 168
    } else if (duration === 30) {
      due = '1M'
      limit = 720
    } else if (duration === 360) {
      due = '1Y'
      limit = 365
    }
    let url = `https://data.ramzarz.news/api/data/usd/${mapSymbol(slug.toLowerCase())}?interval=${due}`

    /*if (duration === 1) {
      url = url + '&aggregate=5';
    }*/
    setLoading(true)
    axios.get(url).then(function (resp) {
      setData(resp.data.data.map((r: { time: string, value: string }) => {
        return [r.time, r.value];
      }))
    }).catch(function (err) {

    }).finally(function () {
      setLoading(false)
    })
  }, [slug, duration])
  return (
    <>
      {loading && (
        <Box sx={{
          textAlign: 'center',
          background: (theme) => theme.palette.mode === 'light' ? 'rgb(242 244 247 / 62%)!important' : 'rgb(242 244 247 / 16%)!important'
        }}>در حال بارگذاری...</Box>
      )}
      {!loading && (

          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
          />
      )}
    </>
  )
}
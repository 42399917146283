import {Box} from "@mui/material";
import * as React from "react";
import OtpDetails from "../../components/OtpDetails";

export default function Otp() {
  return (
    <Box>
      <OtpDetails/>
    </Box>
  );
}

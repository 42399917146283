import React, {memo, useEffect, useRef, useState} from "react";
import {Box, useTheme} from "@mui/material";

function TradingViewWidget(props) {
  const container = useRef();
  const {
    symbol,
    show
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(
    () => {
      if (trigger && !!symbol && !loaded) {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
                {
                    "hide_legend": true,
                    "allow_symbol_change": false,                  
                    "interval": "1D",
                    "isTransparent": false,
                    "height": 380,
                    "symbol": "${symbol}",
                    "showIntervalTabs": true,
                    "displayMode": "single",
                    "locale": "en",
                    "colorTheme": "${theme.palette.mode === "dark" ? 'dark' : 'light' }"
                }`;
        script.onload = () => {
          setTimeout(() => {
            setLoaded(true);
            setLoading(false);
          }, 2000)
        }
        container.current.appendChild(script);
      }

    },
    [symbol, loaded, trigger]
  );

  useEffect(() => {
    if(Boolean(show) && !loaded){
      setTrigger(true);
    }else{
      setTrigger(false);
    }
  }, [show, loaded]);

  return (
    <>
      {loading && (
        <Box sx={{
          background: (theme) => theme.palette.mode === 'light' ? 'rgb(242 244 247 / 62%)!important' : 'rgb(242 244 247 / 16%)!important',
          height: "380px", width: "100%", textAlign: "center"}}>در حال بارگذاری...</Box>
      )}
      <div className="tradingview-widget-container" ref={container} style={{height: "380px", width: "100%", display: loading ? "none" : "block"}}>
        <div className="tradingview-widget-container__widget" style={{height: "380px", width: "100%"}}></div>
      </div>
    </>
  );
}

export default memo(TradingViewWidget);
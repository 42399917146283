import {useLocalStorage} from 'usehooks-ts';

const useCheckLogin = () => {
  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage<string | null>('atkn', null);

  const login = (token: string) => {
    setAccessToken(token);
  }

  const logout = () => {
    removeAccessToken();
  }

  return {
    accessToken,
    isLoggedIn: !!accessToken,
    login,
    logout
  };
};

export default useCheckLogin;
